.about-me {
    .title {
        font-family: "Lalezar";
        padding: 1rem;
        font-size: 1.5rem;
        color: #24a7a1;
    }
    .main {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .right {
        padding: 1rem;
        line-height: 2rem;
        span {
            color: #24a7a1;
        }
        .email {
            margin-top: 2rem;
            font-size: 1.3rem;
            i {
                color: #24a7a1;
                margin-left: 1rem;
            }
            .links {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 1rem;
                i {
                    margin-left: 2rem;
                    font-size: 2rem;
                    cursor: pointer;
                }
            }
        }
    }
    .left {
        direction: ltr;
        padding: 1rem;
        .img {
            width: 26rem;
            height: 28rem;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .skills,
    .works {
        .list {
            direction: ltr;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
            width: 98%;
            margin: auto;
            align-items: center;
            .skill {
                line-height: 2rem;
                display: grid;
                align-items: center;
                grid-template-columns: max-content 1fr;

                .icon {
                    width: 5rem;
                    height: 5rem;
                    img {
                        width: 60%;
                        height: 60%;
                    }
                }
            }
        }
        .dis {
            font-family: BKoodakBold, "BKoodakBold", tahoma !important;
            direction: rtl !important;
            text-align: start;
        }
    }
    @media (max-width: 600px) {
        .main {
            grid-template-columns: 1fr;
            grid-template-rows: 15rem 1fr;
            .left {
                grid-row: 1/2;
                .img {
                    width: 14rem;
                    height: 15rem;
                }
            }
            .right {
                font-size: 0.9rem;
            }
        }
        .skills,
        .works {
            .list {
                grid-gap: 0.5rem;
                grid-template-columns: 1fr 1fr;

                .icon {
                    width: 4rem !important;
                    height: 4rem !important;
                }
            }
        }
        .works {
            .list {
                grid-template-columns: 1fr;
                .skill {
                    border-bottom: 1px solid rgb(215, 215, 215);
                }
            }
        }
    }
}
