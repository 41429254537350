.answer{
    .label{
        margin: 1rem;
        font-size: 1.3rem;
        color: #24a7a1;
        font-family: "Lalezar";
    }
    .question{
        margin: 1rem;
        line-height: 1.4rem;
    }
    .video{
        max-width: 80%;
        margin: auto;
        iframe{
            width: 100%;
            margin: auto;
            height: 30rem;
        }
        @media(max-width: 600px){
            max-width: 90% !important;
        }
    }
    .next-prv{
        text-align: center;
        margin: 1rem 0;
        .deactive{
            cursor: not-allowed;
            color: gray;
        }
        span{
            color: #24a7a1;
            margin: 0 1rem;
            cursor: pointer;
        }
    }
}

.ans{
    line-height: 1.5rem !important;
    margin-top: 2rem !important;
    .a-img{
        width: 50% !important;
        margin:2rem auto !important;
        img{
            width: 100% !important;
        }
        @media(max-width: 600px){
            width: 95% !important;
        }
    }
}