.footer {
    text-align: center;
    background-color: #24a7a1;
    color: white;
    min-height: 8rem;
    display: grid;
    align-items: center;
    margin-top: 2rem;
    .text {
        font-size: 1.3rem;
    }
    .links{
        i{
            margin: 1rem;
            font-size: 2rem;
            cursor: pointer;
        }
    }
}
