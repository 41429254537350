.articel-container{
    .img{
        width: 80%;
        margin:1rem auto;
        img{
            width: 100%;
            height: auto;
        }
    }
    .title{
        font-size: 1.8rem;
        color: #24a7a1;
        margin: 1rem;
    }
    .article-body{
        margin: 1rem;
        line-height: 1.5rem;
        code {
            font-family: monospace;
            margin: 0 2px;
            padding: 0 5px;
            white-space: nowrap;
            border: 1px solid #eaeaea;
            background-color: #f8f8f8;
            border-radius: 3px;
        }
    }
}