@font-face {
    font-family: "BKoodakBold";
    src: url("../public/font/iranyekanwebregular.ttf") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");

html {
    direction: rtl;
    font-size: 16px !important;
    max-width: 100vw;
    overflow-x: hidden;
    font-family: BKoodakBold, "BKoodakBold", tahoma !important;
}

.container {
    width: 970px !important;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1200px) {
        width: 1200px !important;
    }
    @media (max-width: 1000px) {
        width: 98% !important;
        margin: 0 1vw 0 1vw;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.eng{
    font-family: 'Courier New', Courier, monospace !important;
}

code {
    font-family: monospace;
    margin: 0 2px;
    padding: 0 5px;
    border: 1px solid #eaeaea;
    background-color: #f8f8f8;
    border-radius: 3px;
    direction: ltr !important;
}
