@import url(https://fonts.googleapis.com/css2?family=Lalezar&display=swap);
.footer{text-align:center;background-color:#24a7a1;color:white;min-height:8rem;display:grid;align-items:center;margin-top:2rem}.footer .text{font-size:1.3rem}.footer .links i{margin:1rem;font-size:2rem;cursor:pointer}

.about-me .title{font-family:"Lalezar";padding:1rem;font-size:1.5rem;color:#24a7a1}.about-me .main{display:grid;grid-template-columns:1fr 1fr}.about-me .right{padding:1rem;line-height:2rem}.about-me .right span{color:#24a7a1}.about-me .right .email{margin-top:2rem;font-size:1.3rem}.about-me .right .email i{color:#24a7a1;margin-left:1rem}.about-me .right .email .links{display:flex;flex-direction:row;align-items:center;margin-top:1rem}.about-me .right .email .links i{margin-left:2rem;font-size:2rem;cursor:pointer}.about-me .left{direction:ltr;padding:1rem}.about-me .left .img{width:26rem;height:28rem;margin:auto}.about-me .left .img img{width:100%;height:100%}.about-me .skills .list,.about-me .works .list{direction:ltr;display:grid;grid-template-columns:repeat(3, 1fr);grid-gap:1rem;width:98%;margin:auto;align-items:center}.about-me .skills .list .skill,.about-me .works .list .skill{line-height:2rem;display:grid;align-items:center;grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}.about-me .skills .list .skill .icon,.about-me .works .list .skill .icon{width:5rem;height:5rem}.about-me .skills .list .skill .icon img,.about-me .works .list .skill .icon img{width:60%;height:60%}.about-me .skills .dis,.about-me .works .dis{font-family:BKoodakBold, "BKoodakBold", tahoma !important;direction:rtl !important;text-align:start}@media (max-width: 600px){.about-me .main{grid-template-columns:1fr;grid-template-rows:15rem 1fr}.about-me .main .left{grid-row:1/2}.about-me .main .left .img{width:14rem;height:15rem}.about-me .main .right{font-size:0.9rem}.about-me .skills .list,.about-me .works .list{grid-gap:0.5rem;grid-template-columns:1fr 1fr}.about-me .skills .list .icon,.about-me .works .list .icon{width:4rem !important;height:4rem !important}.about-me .works .list{grid-template-columns:1fr}.about-me .works .list .skill{border-bottom:1px solid #d7d7d7}}

.answer .label{margin:1rem;font-size:1.3rem;color:#24a7a1;font-family:"Lalezar"}.answer .question{margin:1rem;line-height:1.4rem}.answer .video{max-width:80%;margin:auto}.answer .video iframe{width:100%;margin:auto;height:30rem}@media (max-width: 600px){.answer .video{max-width:90% !important}}.answer .next-prv{text-align:center;margin:1rem 0}.answer .next-prv .deactive{cursor:not-allowed;color:gray}.answer .next-prv span{color:#24a7a1;margin:0 1rem;cursor:pointer}.ans{line-height:1.5rem !important;margin-top:2rem !important}.ans .a-img{width:50% !important;margin:2rem auto !important}.ans .a-img img{width:100% !important}@media (max-width: 600px){.ans .a-img{width:95% !important}}

.articel-container{padding-top:8rem}.articel-container:after{content:"";width:100%;position:absolute;height:15rem;background-color:#24a7a1;top:0;right:0;z-index:-1}.articel-container .content{background-color:#fff;min-height:70vh;box-shadow:rgba(0,0,0,0.24) 0px 3px 8px;display:grid;grid-column-gap:1rem;-webkit-column-gap:1rem;column-gap:1rem}.articel-container .article-lits{display:grid;grid-template-columns:repeat(3, 1fr);grid-gap:1rem}@media (max-width: 600px){.articel-container .article-lits{grid-template-columns:1fr}}.articel-container .each-article{margin:1rem;box-shadow:rgba(0,0,0,0.24) 0px 3px 8px;height:-webkit-max-content;height:max-content}.articel-container .each-article .img{width:100%;height:auto}.articel-container .each-article .img img{width:100%;height:100%}.articel-container .each-article .info{display:flex;justify-content:space-between;margin-top:1rem;align-items:center}.articel-container .each-article .info .title{color:#24a7a1;font-size:1.2rem;line-height:1.5rem}.no-article{text-align:center;margin:1rem;grid-column:1/4}

.question .title{text-align:center;padding:1rem;font-size:2rem;color:#24a7a1;font-family:"Lalezar"}.question .asked-by{padding:1rem 0;font-size:1.1}.question .asked-by span{color:#24a7a1}.question .question-box{margin:1rem 0;box-shadow:rgba(0,0,0,0.24) 0px 3px 8px;min-height:30rem;font-size:1.3rem;line-height:2.5rem;padding:1rem}

.main-page{min-height:100vh;background-color:#24a7a1}.main-page .main-page-content{padding-top:3.5rem;display:grid;min-height:calc(100vh - 3.5rem);grid-template-rows:3fr 1fr 1fr}.main-page .main-page-content .img{display:grid;place-items:center}@media (max-width: 1500px){.main-page .main-page-content .img img{max-width:350px;max-height:350px}}@media (max-width: 600px){.main-page .main-page-content .img img{max-width:90%;max-height:90%}}.main-page .main-page-content .text{display:grid;align-items:center;color:white}.main-page .main-page-content .text .title{text-align:center;font-size:2.5rem;line-height:3rem;font-family:"Lalezar"}.main-page .main-page-content .text .sub-title{text-align:center;font-size:1.5rem;line-height:2.5rem}.main-page .main-page-content .buttons{display:grid;place-items:center}.main-page .main-page-content .buttons .buts{display:flex;width:-webkit-max-content;width:max-content}.main-page .main-page-content .buttons .buts div{margin:0 2rem;border:2px solid white;padding:0.8rem 1rem;color:white;border-radius:0.2rem;font-size:1.3rem;cursor:pointer;font-weight:500;font-family:"Lalezar";transition:0.3s}.main-page .main-page-content .buttons .buts div:hover{-webkit-transform:scale(1.1, 1.1);transform:scale(1.1, 1.1)}.main-page .main-page-content .buttons .buts .bold{background-color:#fff;color:#24a7a1}@media (max-width: 600px){.main-page .main-page-content .buttons .buts div{margin:0 0.5rem;font-size:1rem;padding:0.5rem 0.8rem}}@media (max-width: 600px){.main-page .main-page-content{grid-template-rows:2fr 1fr 1fr}.main-page .main-page-content .text .title{font-size:2rem;line-height:2.5rem}.main-page .main-page-content .text .sub-title{font-size:1.3rem}}

.navbar{position:absolute;width:100%;background-color:rgba(128,128,128,0.453);color:white;z-index:1}.navbar .content{display:flex;flex-direction:row;justify-content:space-between;font-size:1.2rem;align-items:center;transition:0.3s}.navbar .content .toggle{display:none}.navbar .nav-links{display:flex;cursor:pointer}.navbar .nav-links li{padding:1rem 2rem}.navbar .registion{cursor:pointer}@media (max-width: 600px){.navbar .content{display:block;overflow:hidden;max-height:3.5rem}.navbar .content .toggle{display:block;padding:1rem 2rem}.navbar .content .nav-links{display:block}.navbar .content .registion{padding:1rem 2rem}}.active{max-height:20rem !important;background-color:rgba(128,128,128,0.689)}

.overly{z-index:2;position:fixed;width:100vw;height:100vh;background-color:rgba(191,191,191,0.537);top:0;display:grid;place-items:center}.overly .contnet{width:30rem;min-height:9rem;background-color:#fff}.overly .contnet .head{height:3rem;padding:0 0.5rem;display:flex;align-items:center;justify-content:space-between}.overly .contnet .head .title{font-size:1.1rem}.overly .contnet .head .close{color:red}.overly .contnet .inputs{display:grid;place-items:center}.overly .contnet .inputs .input{width:90%}.overly .contnet .inputs .input input{width:100%;padding:0.2rem 0;font-family:"Lalezar";direction:ltr;font-size:1.1rem}.overly .contnet .v-code{display:grid;align-items:center;direction:ltr}.overly .contnet .v-code .v-inputs{display:grid;grid-template-columns:repeat(5, 1fr);width:90%;margin:auto}.overly .contnet .v-code .v-inputs input{margin:auto;width:2.5rem;height:2.5rem;direction:ltr;font-size:2rem;text-align:center}.overly .contnet .button{text-align:center;margin:1rem}.overly .contnet .button button{background-color:#24a7a1;border:none;border-radius:0.3rem;padding:0.5rem 1rem;color:white;cursor:pointer;font-family:BKoodakBold, "BKoodakBold", tahoma !important}.overly .contnet .sub-title{width:90%;margin:1rem 0}.overly .contnet .skills{width:95%;margin-top:0.4rem;text-align:right;display:grid;grid-template-columns:repeat(3, 1fr);grid-row-gap:0.5rem;row-gap:0.5rem;padding-bottom:1rem}.overly .contnet .notif{width:100%;margin-right:1rem}@media (max-width: 600px){.overly .contnet{width:98%}}.rtl{direction:rtl !important;width:90%}.rtl *{direction:rtl !important}

.profile .title{padding:1rem;font-size:1.5rem;font-family:"Lalezar";color:#24a7a1}.profile .profile-contetn{line-height:2rem;margin:1rem}.profile .profile-contetn .label{color:#24a7a1}.profile .profile-contetn span{padding:0 1rem;color:#24a7a1;cursor:pointer}.profile .exit{margin:1rem;cursor:pointer}.green{color:#11ab11 !important}.red{color:#c61515 !important}

.articel-container .img{width:80%;margin:1rem auto}.articel-container .img img{width:100%;height:auto}.articel-container .title{font-size:1.8rem;color:#24a7a1;margin:1rem}.articel-container .article-body{margin:1rem;line-height:1.5rem}.articel-container .article-body code{font-family:monospace;margin:0 2px;padding:0 5px;white-space:nowrap;border:1px solid #eaeaea;background-color:#f8f8f8;border-radius:3px}

.upload{height:100vh;width:100vw;background-color:#24a7a1;display:grid;place-items:center}.upload .content{color:white;text-align:center}.upload .content .title{font-size:2rem;margin-bottom:2rem}.upload .content .input-file{height:8rem;border:3px dashed #c2c2c2;width:35rem;display:grid;align-items:center;color:#c2c2c2;font-size:1.5rem}.upload .content .file-title{margin-top:2rem;width:35rem}.upload .content .file-title input{width:100%;padding:0.5rem 0;direction:ltr}.upload .content .button{margin-top:2rem}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a{
    text-decoration: none !important;
}
@font-face{font-family:"BKoodakBold";src:url(/static/media/iranyekanwebregular.c463cb48.ttf) format("woff")}html{direction:rtl;font-size:16px !important;max-width:100vw;overflow-x:hidden;font-family:BKoodakBold, "BKoodakBold", tahoma !important}.container{width:970px !important;margin-right:auto;margin-left:auto}@media (min-width: 1200px){.container{width:1200px !important}}@media (max-width: 1000px){.container{width:98% !important;margin:0 1vw 0 1vw}}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type="number"]{-moz-appearance:textfield}.eng{font-family:'Courier New', Courier, monospace !important}code{font-family:monospace;margin:0 2px;padding:0 5px;border:1px solid #eaeaea;background-color:#f8f8f8;border-radius:3px;direction:ltr !important}

