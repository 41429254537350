.profile{
    .title{
        padding: 1rem;
        font-size: 1.5rem;
        font-family: "Lalezar";
        color: #24a7a1;
    }
    .profile-contetn{
        line-height: 2rem;
        margin: 1rem;
        .label{
            color: #24a7a1;
        }
        span{
            padding: 0 1rem;
            color: #24a7a1;
            cursor: pointer;
        }
    }
    .exit{
        margin: 1rem;
        cursor: pointer;
    }
}

.green{
    color: rgb(17, 171, 17) !important;
}

.red{
    color: rgb(198, 21, 21) !important;
}