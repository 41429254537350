.question {
    .title {
        text-align: center;
        padding: 1rem;
        font-size: 2rem;
        color: #24a7a1;
        font-family: "Lalezar";
    }
    .asked-by {
        padding: 1rem 0;
        font-size: 1.1;
        span {
            color: #24a7a1;
        }
    }
    .question-box {
        margin: 1rem 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        min-height: 30rem;
        font-size: 1.3rem;
        line-height: 2.5rem;
        padding: 1rem;
       
    }
}
