.upload{
    height: 100vh;
    width: 100vw;
    background-color: #24a7a1;
    display: grid;
    place-items: center;
    .content{
        color: white;
        text-align: center;
        .title{
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .input-file{
            height: 8rem;
            border: 3px dashed rgb(194, 194, 194);
            width: 35rem;
            display: grid;
            align-items: center;
            color:  rgb(194, 194, 194);
            font-size: 1.5rem;
          
        }
        .file-title{
            margin-top: 2rem;
            width: 35rem;
            input{
                width: 100%;
                padding: 0.5rem 0;
                direction: ltr;
            }

        }
        .button{
            margin-top: 2rem
        }
    }
}