.main-page {
    min-height: 100vh;
    background-color: #24a7a1;
    .main-page-content {
        padding-top: 3.5rem;
        display: grid;
        min-height: calc(100vh - 3.5rem);
        grid-template-rows: 3fr 1fr 1fr;
        .img {
            display: grid;
            place-items: center;
            @media (max-width: 1500px) {
                img {
                    max-width: 350px;
                    max-height: 350px;
                }
            }
            @media (max-width: 600px) {
                img {
                    max-width: 90%;
                    max-height: 90%;
                }
            }
        }
        .text {
            display: grid;
            align-items: center;
            color: white;
            .title {
                text-align: center;
                font-size: 2.5rem;
                line-height: 3rem;
                font-family: "Lalezar";
            }
            .sub-title {
                text-align: center;
                font-size: 1.5rem;
                line-height: 2.5rem;
            }
        }
        .buttons {
            display: grid;
            place-items: center;
            .buts {
                display: flex;
                width: max-content;
                div {
                    margin: 0 2rem;
                    border: 2px solid white;
                    padding: 0.8rem 1rem;
                    color: white;
                    border-radius: 0.2rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    font-weight: 500;
                    font-family: "Lalezar";
                    transition: 0.3s;
                    &:hover{
                        transform: scale(1.1,1.1);
                    }
                }
                .bold {
                    background-color: #fff;
                    color: #24a7a1;
                }
            }
            @media (max-width: 600px) {
                .buts{
                    div{
                        margin: 0 0.5rem;
                        font-size: 1rem;
                        padding: 0.5rem 0.8rem;
                    }
                }
            }
        }
        @media (max-width: 600px) {
            grid-template-rows: 2fr 1fr 1fr;
            .text {
                .title {
                    font-size: 2rem;
                    line-height: 2.5rem;
                }
                .sub-title {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
