.overly{
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color:rgba(191, 191, 191, 0.537);
    top: 0;
    display: grid;
    place-items: center;
    .contnet{
        width: 30rem;
        min-height: 9rem;
        background-color: #fff;
       
        .head{
            height: 3rem;
            padding: 0 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
                font-size: 1.1rem
            }
            .close{
                color: red;
            }
        }
        .inputs{
            display: grid;
            place-items: center;
            .input{
                width: 90%;
                input{
                    width: 100%;
                    padding: 0.2rem 0;
                    font-family: "Lalezar";
                    direction: ltr;
                    font-size: 1.1rem;
                }
            }
        }
        .v-code{
            display: grid;
            align-items: center;
            direction: ltr;
            .v-inputs{
                display: grid;
                grid-template-columns: repeat(5,1fr);
                width: 90%;
                margin: auto;
                input{
                    margin: auto;
                    width: 2.5rem;
                    height: 2.5rem;
                    direction: ltr;
                    font-size: 2rem;
                    text-align: center;
                }
            }
        }
        .button{
            text-align: center;
            margin: 1rem;
            button{
                background-color: #24a7a1;
                border: none;
                border-radius: 0.3rem;
                padding: 0.5rem 1rem;
                color: white;
                cursor: pointer;
                font-family: BKoodakBold, "BKoodakBold", tahoma !important;

            }
        }
        .sub-title{
            width: 90%;
            margin: 1rem 0;
        }
        .skills{
            width: 95%;
            margin-top: 0.4rem;
            text-align: right;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            row-gap: 0.5rem;
            padding-bottom: 1rem;
        }
        .notif{
            width: 100%;
            margin-right: 1rem;
        }
        @media(max-width: 600px){
            width: 98%;
        }
        
    }
}
.rtl{
    direction: rtl !important;
    width: 90%;
    *{
        direction: rtl !important;
    }
}