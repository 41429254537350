.articel-container{
    padding-top: 8rem;
    &:after{
        content: "";
        width:100%;
        position: absolute;
        height: 15rem;
        background-color: #24a7a1;
        top: 0;
        right: 0;
        z-index: -1;

    }
    .content{
        background-color: #fff;
        min-height: 70vh;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: grid;
        column-gap: 1rem;

    }
    .article-lits{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 1rem;
        @media(max-width: 600px){
            grid-template-columns: 1fr;
        }
    }
    .each-article{
       margin :1rem;
       box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        height: max-content ;
        .img{
            width: 100%;
            height: auto;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .info{
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            align-items: center;
            .title{
                color: #24a7a1;
                font-size: 1.2rem;
                line-height: 1.5rem;
            }
        }
    }
}

.no-article{
    text-align: center;
    margin: 1rem;
    grid-column: 1/4;
}