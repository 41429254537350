.navbar {
    position: absolute;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.453);
    color: white;
    z-index: 1;
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.2rem;
        align-items: center;
        transition: 0.3s;
        .toggle {
            display: none;
        }
    }
    .nav-links {
        display: flex;
        cursor: pointer;
        li {
            padding: 1rem 2rem;
        }
    }
    .registion{
        cursor: pointer;
    }
    @media (max-width: 600px) {
        
        .content {
            display: block;
            overflow: hidden;
            max-height: 3.5rem;
            .toggle {
                display: block;
                padding: 1rem 2rem;
            }
            .nav-links {
                display: block;
            }
            .registion {
                padding: 1rem 2rem;
            }
        }
    }
}

.active{
    max-height: 20rem !important;
    background-color: rgba(128, 128, 128, 0.689);

}